<template>
  <div class="navbar">
    <div class="l-content" style="padding: 0 12px" @click="toggleClick">
      <svg
        :class="{ 'is-active': isActive }"
        class="hamburger"
        viewBox="0 0 1024 1024"
        xmlns="http://www.w3.org/2000/svg"
        width="64"
        height="64"
      >
        <path
          d="M408 442h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zm-8 204c0 4.4 3.6 8 8 8h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56zm504-486H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 632H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM142.4 642.1L298.7 519a8.84 8.84 0 0 0 0-13.9L142.4 381.9c-5.8-4.6-14.4-.5-14.4 6.9v246.3a8.9 8.9 0 0 0 14.4 7z"
        />
      </svg>
      <h1>首页</h1>
    </div>
    <div class="right-menu">
      <el-dropdown>
        <div class="avatar-wrapper">
          <el-avatar
            class="user-avatar"
            :size="50"
            :src="avatar_url"
            shape="square"
          >
          </el-avatar>
          <el-icon class="el-icon--right"> </el-icon>
        </div>

        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item>个人中心</el-dropdown-item>
            <el-dropdown-item>退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
const avatar_url = require("@/assets/avatar1.png");
const store = useStore();
const isActive = computed(() => {
  return !store.state.app.isCollapse;
});

const toggleClick = () => {
  return store.commit("app/collapse");
};
</script>



<style lang="scss" scoped>
.navbar {
  overflow: hidden;
  position: relative;
 /*  background: linear-gradient(10deg, rgb(255, 238, 6), rgb(255, 1, 1));
  background: linear-gradient(20deg, rgb(6, 150, 104), rgb(3, 219, 86));
  background: linear-gradient(50deg, rgb(5, 193, 240), rgb(18, 106, 215)); */
  background: #0270C1;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hamburger {
  /* display: inline-block;
vertical-align: middle; */
  margin-right: 20px;
  width: 20px;
  height: 20px;
}

.hamburger.is-active {
  transform: rotate(180deg);
}

.avatar-wrapper {
  margin-top: 5px;
  position: relative;
  cursor: pointer;

  .user-avatar {
    cursor: pointer;
    width: 40px;
    height: 40px;
    /* border-radius: 10px; */
  }

  .el-icon-caret-bottom {
    cursor: pointer;
    position: absolute;
    right: -20px;
    top: 25px;
    font-size: 12px;
  }
}

.right-menu {
  cursor: pointer;
  margin-right: 10px;
}

.rowBC {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.l-content {
  display: flex;
  align-items: center;
}
</style>