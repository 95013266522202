<template>
 <template v-if="!item.hidden">
  <!-- 根路由 -->
  <template v-if="showSidebarItem(item.children, item)">
      <!-- <router-link :to="resolvePath(onlyOneChild.path)"> -->
    <el-menu-item :index="resolvePath(onlyOneChild.path)" :class="{ 'submenu-title-noDropdown': !isNest }">
      <sidebar-ico :elSvgName="onlyOneChild.meta?.ico || item.meta?.ico" />
      <template #title><span>{{onlyOneChild.meta?.title}}</span></template>
    </el-menu-item>
      <!-- </router-link> -->
  </template>
  <!-- 子项路由 -->
   <el-sub-menu v-else :index="resolvePath(item.path)">
        <template #title v-if="item.meta">
          <sidebar-ico :elSvgName="item.meta?.ico" />
          <span>{{ item.meta.title }}</span></template>
        <SidebarItem
        v-for="child in item.children"
        :key="child.path"
        :is-nest="true"
        :item="child"
        :base-path="resolvePath(child.path)"
      />
      </el-sub-menu>
</template>
</template>
<script setup>
import { defineProps, getCurrentInstance } from "vue";
import path from 'path'
import SidebarIco from './SidebarIco.vue'
let { proxy } = getCurrentInstance();
proxy.onlyOneChild = null;
//显示菜单项
let showSidebarItem = (children = [], parent) => {
  const showingChildren = children.filter((item) => {
    if (item.hidden) {
      //根据hidden属性来是否显示
      return false;
    } else {
      proxy.onlyOneChild = item;
      console.log();
      return true;
    }
  });
  
  if (showingChildren.length === 0) { 
    proxy.onlyOneChild = { ...parent, path: "", noChildren: true };
    return true;
  }
   if (showingChildren.length === 1 && !parent?.alwaysShow) {  //如果就一个子项 那就是根节点 只显示一个菜单项
   console.log(proxy.basePath)
    return true;
  }

  return false;
}
let resolvePath = (routePath) => {
  return path.resolve(proxy.basePath, routePath)
}

defineProps({
  // route object
  item: {
    type: Object,
    required: true,
  },
   //用于判断是不是子Item,设置响应的样式
  isNest: {
    type: Boolean,
    default: false
  },
  basePath: {
    type: String,
    default: "",
  },
});
</script>